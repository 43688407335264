<script setup lang="ts">
const props = defineProps({
    notification: {
        type: Object
    }
})

const dismissMessage = ref(false)

// Remove notification message
const dismiss = () => {
    // Store dismissed notification without expiration date
    setCookie('dismissedNotificationId', props.notification.id);

    dismissMessage.value = true
};
</script>

<template>
    <div 
        class="w-full border border-red-300 bg-red-300/25 rounded-lg flex items-start gap-4 overflow-hidden transition-all duration-500"
        :class=" { 
            'max-h-0 opacity-0 p-0 mb-0': dismissMessage,
            'max-h-full p-3 mb-4 opacity-1': !dismissMessage
        } ">
        <div class="relative w-12 h-12 flex justify-center items-center text-red-600 font-bold">
            <div class="flex justify-center items-center rounded-full border-2 border-red-300/50 w-12 h-12">
                <div class="flex justify-center items-center rounded-full border-2 border-red-500/50 w-9 h-9">
                    <div class="flex justify-center items-center rounded-full border-2 border-[--color-error] w-6 h-6">
                        !
                    </div>
                </div>
            </div>
            <img src="/img/bg_pattern.svg" class="absolute -top-0 -left-0 z-0 " data-v-inspector="components/sidebar.vue:85:29">
        </div>

        <div class="flex flex-col">
            <h4 class="text-[--color-error] font-bold text-sm mb-1">
                {{ notification.title }}
            </h4>
    
            <p class="text-[--color-error] text-sm mb-2">
                {{ notification.description }}
            </p>

            <button type="button" class="w-fit text-red-600 font-bold text-sm" @click="dismiss()">Dismiss</button>
        </div>

        <button type="button" class="ml-auto w-fit text-red-600 font-bold text-sm" @click="dismiss()">
            <span class="icon ralph-cross"></span>
        </button>
    </div>
</template>